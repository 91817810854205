import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import homedata from "../data/Homepage.json";
import { getFBVideos, getFeaturedFBVideos } from "../api/homepageApi";
import { useDispatch, useSelector } from "react-redux";
import { handleLang } from "../utils/Helper";
import styles from "../styles/Facebook.module.css";
import { useRouter } from "next/router";

const FacebookStream = () => {
  const [facebookPageDate, setfacebookPageData] = useState("");
  const [facebookLiveVideoData, setfacebookLiveVideoData] = useState([]);
  const [selectLang, setSelectedLang] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  const { data, isLoading } = useQuery("facebookVideos", getFBVideos, {
    select: (data) => data?.data,
  });//getFeaturedFBVideos

  const { data: featureVideo } = useQuery("featureFacebookVideos", getFeaturedFBVideos, {
    select: (data) => data?.data,
  });//getFeaturedFBVideos

  // console.log({url: data})

  const [activeVideo, setActiveVideo] = useState();

  useEffect(() => {
    if (!isLoading){
      setfacebookLiveVideoData(data)
      setActiveVideo(data[0])
      
    }
  }, [data]);

  useEffect(() => {
    if(data && featureVideo){
      data[0] = featureVideo[0];
      setfacebookLiveVideoData(data)
    }
  }, [featureVideo]);

  useEffect(() => {
    const lang = handleLang()
    const language = JSON.parse(lang)
    if (globalselectedlang == "en" || language == "en") {
      setfacebookPageData(homedata.en)
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setfacebookPageData(homedata.hi)
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setfacebookPageData(homedata.ta)
    }
    setSelectedLang(language)
  }, [globalselectedlang])



  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
const router  = useRouter();

  return (
    <>
      <div className={styles.facebook}>
        <div className={styles.fbtitle}>
          <h1>
           
            <img src="/assests/icons/om.png" alt="" />
            {
             
              facebookPageDate?.FacebookTitle
             
            }
            <img src="/assests/icons/om.png" alt="" />{" "}
          </h1>
          <p>
            
          {

              facebookPageDate?.FacebookSubtitle
          }
          </p>
        </div>

        <div className={styles.dashboard}>
          <div className={styles.FacebookDashboard}>
            <div className={styles.left}>
              <iframe
                scrolling="no"
                allowFullScreen={true}
                style={{
                  border: "none",
                  overflow: "hidden",
                  aspectRatio: "16/9",
                  borderRadius: "1vw",
                }}
               className = {styles.leftMainVid}

                src={activeVideo?.attributes?.Facebook_url}
              ></iframe>
            </div>

            <div className={styles.right}>
              {facebookLiveVideoData
                ?.filter((sr) => sr?.id !== activeVideo?.id)
                ?.map((video, i) => (
                  <div className={styles.rightWrapper} key={i}>
                    <>
                      <div style={{ position: "relative", display: "flex" ,cursor:"pointer" }}>
                        <iframe
                          scrolling="no"
                          allowFullScreen={true}
                          className={styles.rightImg}
                          src={video?.attributes?.Facebook_url}
                        ></iframe>
                        <div
                          onClick={() => setActiveVideo(video)}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            }}
                          ></div>
                        </div>

                        <div
                        onClick={() => setActiveVideo(video)}
                         className={styles.rightInfo}>
                          <h5>{video?.attributes?.Live_events_details}</h5>
                          <h4 className={styles.org}>Shaivam.Org</h4>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.btn}>
          <h2> {facebookPageDate?.FacebookUndertitle  } </h2>
          <a target="_blank" href="https://www.facebook.com/shaivamorg">
          <button style={{ cursor: "pointer", fontFamily: "Lora" }} >
              <img src="/assests/icons/fb.png" alt="" /> Follow
          </button></a>
        </div>
        <div className={styles.shivbg}>
          <img src={`${facebookPageDate?.imgOne}`} alt="" />
        </div>
      </div>
    </>
  );
};

export default FacebookStream;
